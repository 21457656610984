/* DevotionalManager.css */

.react-calendar__tile--highlight {
    background: #c6f7ee;
    color: rgb(0, 0, 0);
    border-radius: 0%;
  }
  
  .react-calendar__tile--highlight:enabled:hover,
  .react-calendar__tile--highlight:enabled:focus {
    background: #282d5e;
    color: white;
    border-radius: 0%;
  }